@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Roboto:wght@400;500;700&display=swap');

.print {
  .logo-wrapper {
    text-align: right;
  }
  .logo-wrapper img {
    width: 120px;
    margin-top: -20px;
  }
  .logo {
    margin: 0;
    margin-left: auto;
  }
  .measurments {
    text-align: left;
  }
  .measurments img {
    width: 70%;
    margin-top: 2px; // will get rid of weird line corruption
  }

  h1.header {
    margin: 0;
    text-align: left;
    color: black;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 0;
    font-size: 1.7rem;
    font-weight: 600;
  }
  h1.subheader {
    margin: 0;
    text-align: left;
    font-weight: 300;
    border-bottom: 4px solid black;
    color: black;
    padding-bottom: 20px;

    padding-left: 50px;
    padding-right: 50px;
    font-size: 1.7rem;
  }

  .wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }

  .item a {
    text-decoration: none;
  }
}